import React from "react";
import axios from 'axios';

import { NavLink, useNavigate, useParams} from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Context} from "../context";
import {useContext, useState} from "react";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Back = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const Password = (props) => {
    
    let {link} = useParams()
    let email = link?.split('~')[0].split(':')[1]
    let key = link?.split('~')[1].split(':')[1]
    const contextValue=useContext(Context)
    const navigate = useNavigate();
    axios.defaults.withCredentials=true;
    const [pass,setPass] = useState ({
        link:key,
        new_password:'',
        new_password_check:''
    })
    
    function handleSubmit(event)
    {

        console.log(pass)
        event.preventDefault();
        axios.post("/api/users/password/restore",pass)
        .then(res=>{
            if(res.data.Status==="Пароль успешно изменен")
            {
                console.log(res.data)
                    contextValue.setAuth(true)
                    contextValue.setUserEmail(res.data.user_email) 
                    contextValue.setUserId(res.data.user_id) 
                    contextValue.setSchoolID(res.data.school_id) 
                    contextValue.setTrenerID(res.data.trener_id)
                    contextValue.setUserType(res.data.user_type_id)
                    contextValue.setSuperUser(res.data.super_user)
                    contextValue.setTypeMsg("success")
                    contextValue.setMsg("Пароль успешно изменен, авторизация выполнена.")
                    contextValue.setOpenAlert(true)
                    navigate('/sportsmens')
            }
            else alert(res.data.Error);
        })
        .catch(err =>alert(err));
    }

    
    return ( 
        <Back sx={{mx:'5%', my:'2%', px:'5%', py:'2%'}}>
            <form onSubmit={handleSubmit}>  
                <Box
                sx={{
                    display: 'grid',                    
                    gridAutoColumns: '1fr',
                    gap: 2,
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    alignContent:'center',
                    alignItems:'center'               
                }}
                >
                      
                             <Typography
                             
                        variant="h3"
                        sx={{ my:1,
                        display: { xs: 'flex', md: 'flex' },
                        
                        fontFamily: 'Poppins',
                        textAlign:'center',
                        color: 'inherit',
                        textDecoration: 'none',
                        }}
                    >{`Восстановление пароля для пользователя ${email}`} 
                        </Typography> 
                        <TextField
                    required
                    fullWidth
                    sx={{my:1}}
                    type="password"
                    label="Новый пароль"
                    onChange={e=>setPass({...pass, new_password:e.target.value})}
                />
                <TextField
                    fullWidth
                    sx={{my:1}}
                    required
                    type="password"
                    label="Повторите новый пароль"
                    onChange={e=>setPass({...pass, new_password_check:e.target.value})}
                />

               
                <Button                
                            variant="contained"
                            key="login"
                            onClick={handleSubmit}
                        >Сменить пароль</Button>  
                    <Button                
                            variant="outlined"
                            key="cancel"
                            onClick={()=>navigate('/')}
                        >Отмена </Button>  
                </Box>
            </form>
            </Back> 
         );
}
 
export default Password;