import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import AddSportsmen from "../ModalWindow/AddSportsmen/AddSportsmen";
import Button from '@mui/material/Button';
import React from "react";
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs,{ tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import { useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddSostavInOrder from "../components/addMemberInOrder/AddSostavInOrder";
import { NavLink, useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { makeStyles } from '@mui/styles';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import Toolbar from '@mui/material/Toolbar';

import Drawer from '@mui/material/Drawer';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import AddResult from "./AddResult";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f2f3f4',
  textAlign: 'center'
}));

const theme = createTheme({
  shadows: ["none"]
});

theme.typography = {
  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

const Input = styled(MuiInput)`
  width: 42px;
`;





const BrigadeMembers = (props) => {
  const contextValue=useContext(Context)
  const [showResult,setShowResult]=useState(false)
  const [select,setSelect]=useState(null)
  const [members,setMembers]=useState(null)
    const headers=[];


    useEffect(()=>
      {
        props.active && setMembers(props.orders)

      },[props.active])

    function HeadersCheck(header)
      {
        if(headers.includes(header))
        {
          return '';
        }
        else
        {
          headers.push(header);
          return header;
        }        
      }
    
    return (       
        <>
        <Helmet>
        <title>Бригада</title>
        <meta name="description" content="График соревнования" />
      </Helmet>    
      {showResult&&<FormDialog title="Результат" width={'lg'} active={showResult} setActive={setShowResult} children={AddResult} orders={select} setOrders={setSelect}/>}
            <div class="project-details" > 
                <div className="max-w-full" >    
                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>  
                                    {members?.map((o,i)=>(
                                    <>
                                    <Grid item xs={12} md={12} >
                                    {contextValue.championat.championat_style_name==="Парно-групповое"?
                                      
                                      <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                        <strong>{HeadersCheck(`${o.composition_type_name} ${o.group_category_name} ${o.razryad_short_name+","} ${o.sostav_type_name}`)}</strong>
                                        </Typography>
                                      :                                      
                                      <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                        <strong>{HeadersCheck(`${o.sportsmen_gender==="ж"?"Девочки ":"Мальчики "} ${o.age+ " г.р. "}${contextValue.championat.championat_style_name!=="КПН"?o.razryad_short_name:o.kpn+" г.п."}`)}</strong>
                                        </Typography>}
                                      </Grid>
                                      <Item  elevation={1} sx={{my:2}} onClick={()=>{
                                        setSelect(o)
                                        setShowResult(true)}}>
                                      <Grid container alignItems={"stretch"} columns={12} spacing={1}>

                                        
                                      <Grid item sx={{borderRight:"1px solid"}} xs={1} sm={1} display="flex"
                                            justifyContent="center"
                                            flexDirection="column">                                    
                                        
                                        <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">
                                              {o.Number}
                                            </Typography>
                                        </Grid> 
                                         
                                        <Grid item sx={{borderRight:"1px solid"}} xs={4} sm={3} display="flex"
                                            justifyContent="center"
                                            flexDirection="column">
                                        <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                              {contextValue.championat.championat_style_name!=="КПН"&&`Разминка: ${o.RazminkaTime}\n`}{`Выступление: ${o.Time}`}
                                        </Typography>  
                                        </Grid> 
                                                                        
                                        <Grid item sx={{borderRight:"1px solid"}} xs={4} sm={3} display="flex"
                                            >
                                        <Typography variant="caption" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="left">
                                              {o.FN}
                                        </Typography>
                                        </Grid>

                                        {o.score!==null && <Grid item sx={{borderRight:"1px solid"}} xs={1} sm={1} display="flex" justifyContent="center" flexDirection="column"
                                            >
                                        <Typography variant="caption" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                              {parseFloat(o.score).toFixed(2)}
                                        </Typography>
                                        </Grid>}

                                        {o.score!==null && 
                                        (contextValue.championat.championat_style_name==="Парно-групповое" &&
                                        <Grid item sx={{borderRight:"1px solid"}} xs={1} sm={1} display="flex" justifyContent="center" flexDirection="column">
                                        <Typography variant="caption" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                              {parseFloat(o.show_score).toFixed(2)}
                                              </Typography>
                                        </Grid>)}
                                        
                                        <Grid item sx={{borderRight:{xs:"0px solid",sm:"1px solid"}}} xs={2} sm={1}  display="flex"
                                            justifyContent="center"
                                            flexDirection="column">
                                          
                                        <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">{o.age}
                                        </Typography> 
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={2} alignItems={"center"} display="flex"
                                            justifyContent="center"
                                            flexDirection="column">
                                        <Divider sx={{ bgcolor: "black", display:{xs:'flex', sm:'none'} }} variant="middle" flexItem />
                                        <Typography variant="caption" display="block" gutterBottom  sx={{ 
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">
                                              {contextValue.championat.championat_type_name==="Школьные"?o.treners:o.school_name} 
                                        </Typography>
                                        </Grid>
                                        </Grid>
                                        
                                        </Item> 
                                   
                                    </> ))
                                  }
                        </div>
                    </div>
            </>
     );
}
 
export default BrigadeMembers;