import React from "react";
import axios from 'axios';

import { NavLink, useNavigate} from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Context} from "../context";
import {useContext, useState} from "react";
const Login = (props) => {
    // let {user_id} = useParams()
    const contextValue=useContext(Context)
    const navigate = useNavigate();
    axios.defaults.withCredentials=true;
    const [values,setValues] = useState ({
        email:'',
        password:''
    })

    function handleSubmit(event)
    {
        console.log(values)
        event.preventDefault();
        axios.post("/api/users/login",values)
        .then(res=>{
            if(res.data.Status==="Авторизация успешна")
            {
                console.log(res.data)
                axios.post("/")
                .then(res=>{
                  if(res.data.Status.includes("Авторизация успешна"))
                  {
                    contextValue.setAuth(true)
                    contextValue.setUserEmail(res.data.user_email) 
                    contextValue.setUserId(res.data.user_id) 
                    contextValue.setSchoolID(res.data.school_id) 
                    contextValue.setTrenerID(res.data.trener_id)
                    contextValue.setUserType(res.data.user_type_id)
                    contextValue.setSuperUser(res.data.super_user)
                    contextValue.setTypeMsg("success")
                    contextValue.setMsg("Вход выполнен")
                    contextValue.setOpenAlert(true)
                    navigate('/sportsmens')
                    setTimeout(props.setActive(false), 5000); 
                  }
                  else
                  {
                    contextValue.setAuth(false)
                  }
                })
            }
            else alert(res.data.Error);
        })
        .catch(err =>alert(err));
    }
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };
    function handleResore(event)
    {
        if(values.email!=='' && values.email!==null && validateEmail(values.email))
       { event.preventDefault();
        axios.post(`/api/users/password/restore_mail/${values.email}`)
        .then(res=>{
            if(res.data.Status==="На почту направлено письмо для восстановления пароля")
            {
                
                    contextValue.setTypeMsg("success")
                    contextValue.setMsg("На почту направлено письмо для восстановления пароля")
                    contextValue.setOpenAlert(true)
                  }
                  else
                  {
                    contextValue.setTypeMsg("error")
                    contextValue.setMsg(res.data.Error)
                    contextValue.setOpenAlert(true)
                  }
                })
            
        .catch(err =>alert(err));}
        else
        {
            contextValue.setTypeMsg("error")
                    contextValue.setMsg("Email не корректен")
                    contextValue.setOpenAlert(true)
        }
    }

    
    return ( 
            <form onSubmit={handleSubmit}>  
                <Box
                sx={{
                    display: 'grid',                    
                    gridAutoColumns: '1fr',
                    gap: 2,
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    alignContent:'center',
                    alignItems:'center'               
                }}
                > 
                <TextField
                    required
                    id="outlined-required"
                    label="Email"
                    onChange={e=>setValues({...values, email:e.target.value})}
                />
                <TextField
                    required
                    id="outlined-required"
                    type="password"
                    label="Пароль"
                    onChange={e=>setValues({...values, password:e.target.value})}
                />

               
                <Button                
                            variant="contained"
                            key="login"
                            onClick={handleSubmit}
                        >Вход</Button>  
                    <Button                
                            variant="outlined"
                            key="cancel"
                            onClick={()=>props.setActive(false)}
                        >Отмена </Button>  
                        <Button                
                            onClick={handleResore}
                        >Забыли пароль? </Button>            
                </Box>
            </form>
         );
}
 
export default Login;