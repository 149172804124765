
import {NavLink,useNavigate} from "react-router-dom";
import { useState,useEffect,useContext } from "react";
import image1 from "./../img/picts/img1.jpg";
import image2 from "./../img/picts/img2.jpg";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Login from "./Login";
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import ImageUpload from "./ImageUpload";
import Registration from "./Registration";
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CloseIcon from '@mui/icons-material/Close';
import {Context} from "../context";

const useStyles = makeStyles((theme) => ({
    behind: {
        position: 'absolute', // или 'relative'
        zIndex: 0, // ставим отрицательное значение для размещения позади
    },
}));

const Home = (props) => { 
  
  const contextValue=useContext(Context)
const[itemData,setItemData] = useState([])   
const[loginWindow,loginWindowActive]=useState(false)
const[regWindow,regWindowActive]=useState(false)
const classes = useStyles();
async function handleDelete(imageName) 
{
      try {
          await axios.delete(`/image_delete/${imageName}`);
          setItemData(itemData.filter(x=>x!==imageName))
          contextValue.setTypeMsg("info")
          contextValue.setMsg(`Изображение ${imageName} успешно удалено.`)
          contextValue.setOpenAlert(true)
          // alert('Изображение успешно удалено');
      } catch (error) {
          console.error('Ошибка при удалении изображения:', error);
          alert('Произошла ошибка при удалении изображения');
      }
  }
  function DownloadImage()
    {            
        axios.get(`/files`)
        .then(res=>{setItemData(res.data)})
        .catch(err=>console.log(err)) 
    } 

    useEffect(()=>{DownloadImage()},[])
    return ( 
      <>
       <Helmet>
        <title>Главная</title>
        <meta name="description" content="Система автоматизации проведения соревнований по спортивной акробатике" />
      </Helmet>
      <main class="section">
      <div className={classes.behind}        >
        
      </div>
      {loginWindow&&<FormDialog title={"Авторизация"}  active={loginWindow} setActive={loginWindowActive} children={Login}  />}
      {regWindow&&<FormDialog title={"Регистрация"}  active={regWindow} setActive={regWindowActive} children={Registration}/>}
      <div style={{ zIndex: 1, position: 'relative' }}>
        <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
        >
         <ImageList variant="masonry"
        cols={4} gap={2} sx={{height: '100vh' }}>
        {itemData.map((item) => (
          <ImageListItem key={item}>
            <img
              srcSet={`${`./uploads/${item}`}?w=248&h=496&fit=crop&auto=format&dpr=2 2x`}
              src={`${`./uploads/${item}`}?w=248&h=496&fit=crop&auto=format`}
              loading="lazy"
            />
            {contextValue.super_user===1&&<ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
              }}
              position="top"
              actionIcon={
                <IconButton
                  sx={{ color: 'white' }}
                  onClick={()=>handleDelete(item)}
                >
                  <CloseIcon />
                </IconButton>
              }
              actionPosition="right"
            />}
          </ImageListItem>
        ))}
      </ImageList> 
      </Grid>
      
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={3} square>
          <Box 
            sx={{
              
              flexGrow: 0,
              alignContent: 'flex-end',
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
              <Typography
              
            variant="h3"
            sx={{
              fontWeight: 700,
              letterSpacing: '.3rem',
              display: { xs: 'flex', md: 'flex' },
              fontFamily: 'Poppins',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            ACROpro
        </Typography> 
            <Typography
            variant="h6"
            sx={{
              display: { xs: 'flex', md: 'flex' },
              fontFamily: 'Poppins',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Система автоматизации проведения соревнований по спортивной акробатике
        </Typography> 
                <Box
                  sx={{
                    display: 'grid',                    
                    gridAutoColumns: '1fr', 
                    my: 30,                
                    gap: 2,
                    gridTemplateColumns: 'repeat(2, 1fr)',                
                  }}
                > 
                <Button                
                variant="contained"
                key="login"
                onClick={()=>loginWindowActive(true)}
                >Вход </Button>

                <Button
                key="login"
                variant="contained"
                onClick={()=>regWindowActive(true)}
                >Регистрация</Button>
                </Box>    
          </Box>           
       
      </Grid>
      </Grid>
      {contextValue.super_user===1&&<ImageUpload/>}
      </div>
      </main>
        </>
      );
}
 
export default Home;