import { useContext,useEffect,useState } from "react";
import {Context} from "../../context";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { NavLink } from "react-router-dom";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormDialog from "../../ModalWindow/Dialog/FormDialog";
import AddTrener from "../../ModalWindow/AddTrener/AddTrener";
import axios from 'axios';
import TrenerPage from "../../pages/TrenerPage";
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import Typography from '@mui/material/Typography';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TrenersTable = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openDellete, setOpenDellete] = React.useState(false);
  const contextValue=useContext(Context)
  
    const[editTrenerActive,seteditTrenerActive]=useState(false)
    const [find, setFind] =useState('')
    const [treners, setTreners] =useState([])
    const [ftreners, setFTreners] =useState([])
    const [values,setValues] = useState ({
      id:'',
      fio:''
    })

    function handleClickOpen(values) {
      setValues(values)
      setOpen(true);
    };
  
    const handleClose = () => {        
        setOpen(false);
    };

   
    useEffect(()=>
    {
      props.update && 
                getTreners()
    },[props.update]);


    useEffect(()=>
    {
      !editTrenerActive && (!props.needUpdate &&
                props.setUpdate(true))
    },[editTrenerActive]);

    useEffect(()=>
    {
      filterTreners()
    },[treners,props.type]);

    async function getTreners()
    {
      await axios.get(`/api/treners/my/${contextValue.school_id}`)
      .then(res=>{
        setTreners(res.data)
        props.setUpdate(false)
      })
      .catch(err=>console.log(err)) 
    }

    function filterTreners()
    {
      if(props.type==="all") setFTreners(treners?.filter(x=>x.archive===0))
      else if(props.type==="trener")setFTreners(treners?.filter(x=>x.archive===0 && x.trener===1))
      else if(props.type==="sud")setFTreners(treners?.filter(x=>x.archive===0 && x.sud===1))
      else if(props.type==="secret")setFTreners(treners?.filter(x=>x.archive===0 && x.secret===1))
      else if(props.type==="archive")setFTreners(treners?.filter(x=>x.archive===1))
    }

    function restoreTrener(values)
    {
      setValues(values)
        axios.post(`/api/treners/trener/restore/${values.id}`,values)
        .then(res=>{
            console.log(res.data.Status)
            if(res.data.Status==="Тренер восстановлен")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg("Тренер восстановлен из архива")
              contextValue.setOpenAlert(true)
              props.setUpdate(true)
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))
      
    }   

    function deleteTrener()
      {
          axios.post(`/api/treners/trener/delete/${values.id}`,values)
          .then(res=>{
              console.log(res.data.Status)
              if(res.data.Status==="Тренер удален")
              {
                contextValue.setTypeMsg("success")
                contextValue.setMsg("Тренер удален")
                contextValue.setOpenAlert(true)
                handleCloseDellete();
                props.setUpdate(true)
              }
              else console.log(res.data.Status);
          })
          .catch(err =>alert(err))
                   
     
    }

    function handleClickOpenDellete(values) {
      setValues(values)
      setOpenDellete(true);
    };
  
    const handleCloseDellete = () => {
      setOpenDellete(false);
    };

    function archivetrener()
    {
      console.log(values)
        axios.post(`/api/treners/trener/archve/${values.id}`)
        .then(res=>{
            console.log(res.data.Status)
            if(res.data.Status==="Тренер помещен в архив")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Сотрудник ${values.fio} в архиве`)
              contextValue.setOpenAlert(true)
              props.setUpdate(true);
              dropTrener();
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))            
    }
    function dropTrener()
    {            
        axios.post(`/api/sportsmens/drop_trener/${values.id}`)
        .then(res=>{
            if(res.data.Status==="Тренер обнулен")
            {
              props.setUpdate(true);
              handleClose();
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))
    } 


    function dateDif(date)
    {
        let date1=new Date();
        let date2=new Date(date.split('.')[2],date.split('.')[1],date.split('.')[0]);
        let diff = (date2.getDate() - date1.getDate()) / 30 +
        date2.getMonth() - date1.getMonth() +
        (12 * (date2.getFullYear() - date1.getFullYear()));
        console.log(diff);
        return diff<3;
    }

    return (  
          <>
          
          <FormDialog title={"Профиль сотрудника"} FormDialog width={'lg'}  active={editTrenerActive} setActive={seteditTrenerActive} children={TrenerPage}/>
           <React.Fragment>            
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Отправить тренера в архив?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Информация о сотруднике: "{values.fio}" будет доступна во вкладке "Сотрудники в архиве". Спортсмены тренера будут доступны во вкладке "Все спортсмены"
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={archivetrener}>Да</Button>
                <Button onClick={handleClose}>Нет</Button>                
              </DialogActions>
            </Dialog>
          </React.Fragment>

          <React.Fragment>            
            <Dialog
              open={openDellete}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseDellete}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Удалить тренера?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Удалить информацию о {values.fio}? Данные о тренере больше не будут доступны!"
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={deleteTrener}>Да</Button>
                <Button onClick={handleCloseDellete}>Нет</Button>                
              </DialogActions>
            </Dialog>
          </React.Fragment>

          <TextField fullWidth name="family" label="Поиск сотрудника" id="fullWidth" sx={{my:2}} onChange={e=>setFind(e.target.value)}/>
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>            
                <TableCell align="center">ФИО</TableCell>
                <TableCell align="center">Дата рождения</TableCell>
                {props.type==="sud" && <TableCell align="center">Судейская категория</TableCell>}
                
                <TableCell align="center">email</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
          <TableBody>
            {
            ftreners?.filter((trener)=>trener.trener_family.includes(find)).map((trener,i)=>(
              <TableRow
              key={trener.trener_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell component="th" scope="row">{i+1}</TableCell>
            <TableCell wrap align="center">
              {`${trener.trener_family} ${trener.trener_name} ${trener.trener_otchestvo}`}              
              </TableCell> 
             <TableCell wrap align="center">{`${trener.trener_age}`}</TableCell> 
              {props.type==="sud" && <TableCell  sx={{
                                whiteSpace: "pre-wrap"
                                
                                }} align="center">
                                  <Typography variant="caption"
                                display="block" 
                                color={trener?.sud_categ_name!==null&&(dateDif(trener.sud_categ_date_end)&&"red")}
                                sx={{                                  
                                fontFamily: 'Poppins',
                                whiteSpace: "pre-wrap"                                           
                                }} align="center">
                    {trener?.sud_categ_name!==null?`${trener?.sud_categ_name?.toString()}\n(до ${trener?.sud_categ_date_end?.toString()})`:"Нет категории"}
              </Typography>
              </TableCell> }



             <TableCell wrap align="center">{`${trener.user_email}`}</TableCell> 
             {props.type!=="archive"?<TableCell wrap align="center">
                  <IconButton  aria-label="edit" onClick={()=>{
                    contextValue.setSelectTrener(trener.trener_id);
                    seteditTrenerActive(true)}}>
                      <EditOutlinedIcon />
                  </IconButton>
                  <IconButton  aria-label="delete" 
                  onClick={()=>handleClickOpen({...values, 
                    id:trener.trener_id, 
                    fio:`${trener.trener_family} ${trener.trener_name} ${trener.trener_otchestvo}`
                  })}>
                      <DeleteOutlineOutlinedIcon />
                  </IconButton>
                 
                  </TableCell> 
                  :
                  <TableCell wrap align="center">
                  <IconButton  aria-label="restore" onClick={()=>restoreTrener({...values, id:trener.trener_id, fio:`${trener.trener_family} ${trener.trener_name} ${trener.trener_otchestvo}`})}>
                      <RestoreOutlinedIcon />
                  </IconButton>
                  <IconButton  aria-label="delete" onClick={()=>handleClickOpenDellete({...values, id:trener.trener_id, fio:`${trener.trener_family} ${trener.trener_name} ${trener.trener_otchestvo}`})}>
                      <DeleteOutlineOutlinedIcon />
                  </IconButton>
                 
                  </TableCell>           }
                  </TableRow>))}          
            </TableBody>
      </Table>
    </TableContainer>    

          </>
         
      );
}
 
export default TrenersTable;