import React, { useState } from 'react';
import axios from 'axios';
import { Button, Container, Typography, Snackbar, Alert } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Back = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const ImageUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setSnackbarMessage('Пожалуйста, выберите файл!');
            setOpenSnackbar(true);
            return;
        }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setSnackbarMessage(`Изображение загружено: ${response.data.filePath}`);
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('Ошибка при загрузке изображения.');
        } finally {
            setOpenSnackbar(true);
            setSelectedFile(null); // Сбросить выбранный файл
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Back sx={{mx:'5%', my:'2%', px:'5%', py:'2%'}}>
        <Container>
            <Typography variant="h4" gutterBottom>
                Загрузите изображение
            </Typography>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleUpload} 
                style={{ marginTop: '16px' }}
            >
                Загрузить
            </Button>
            
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
        </Back>
    );
};

export default ImageUpload;