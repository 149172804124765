import { useContext,useEffect,useState } from "react";
import {Context} from "../../context";
import axios from 'axios';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function isEmpty(str) {
  if (str.trim() == '') 
    return true;
    
  return false;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SostavsTable = () => {
  
  const contextValue=useContext(Context)
  
    const [open, setOpen] = React.useState(false);
    const [sostavs, setSostavs] =useState([])    
    const [values,setValues] = useState ({
      id:''
    })
   
    useEffect(()=>
    {
      axios.get(`/api/sostavs/my/${contextValue.school_id}`)
      .then(res=>{
        console.log(res.data)
        setSostavs(res.data)})
      .catch(err=>console.log(err))
      
      
    },[contextValue.setOpenAlert]);

   
    function deletesostav()
    {            
        axios.post(`/api/sostavs/delete/${values.id}`,values)
        .then(res=>{
            console.log(res)
            if(res.data.Status==="Состав добавлен в архив")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Состав добавлен в архив`)
              contextValue.setOpenAlert(true)
              handleClose();
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))
    } 

    function handleClickOpen(values) {
      setValues(values)
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    return (  
      
    <div className="max-w-full">
       <React.Fragment>            
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Распустить состав?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Информация о составе будет доступка во вкладке "Составы в архиве"
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={deletesostav}>Да</Button>
                <Button onClick={handleClose}>Нет</Button>                
              </DialogActions>
            </Dialog>
          </React.Fragment>         
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>            
            <TableCell align="center">УЧАСТНИКИ</TableCell>
            <TableCell align="center">ВИД</TableCell>
            <TableCell align="center">ГОД РОЖДЕНИЯ</TableCell>
            <TableCell align="center">РАЗРЯД</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {sostavs?.map((sostav,i)=>(
               <TableRow
               key={sostav.sostav_id}
               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
             >
               <TableCell component="th" scope="row">
                  {i+1}
                  </TableCell>
                  <TableCell  sx={{
                                            whiteSpace: "pre-wrap"
                                            
                                            }} align="center">
                  {sostav?.FIO?.replaceAll("..",".")}             
             </TableCell>   
             <TableCell align="center">{sostav.sostav_type_name}                    
             </TableCell>              
             <TableCell  sx={{whiteSpace: "pre-wrap" }} align="center">            
               {sostav.age}                    
                </TableCell>
             <TableCell  sx={{
                                            whiteSpace: "pre-wrap"
                                            
                                            }} align="center">           
                {sostav.razryad}                        
                </TableCell>             
             <TableCell align="center">
              <IconButton aria-label="delete" 
              onClick={()=>handleClickOpen({...values, id:sostav.sostav_id})}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
              </TableCell>
              </TableRow>))}          
            </TableBody>
      </Table>
    </TableContainer>    
    </div>     
  );
}
 
export default SostavsTable;