
import "./style.css";
import { NavLink } from "react-router-dom";
import logo from "./../../img/Logo.png"
import axios from "axios";
import {useNavigate} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import {Context} from "../../context";
import {useContext, useState, useEffect } from "react";
import FormDialog from "../../ModalWindow/Dialog/FormDialog";
import TrenerPage from "../../pages/TrenerPage";


const activelink="nav-list__link nav-list__link--active";
const passivelink="nav-list__link";


const Navbar = (props) => {
  const contextValue=useContext(Context) 
  const[editTrenerActive,seteditTrenerActive]=useState(false)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
    const handleDelete=()=>
    {
      axios.get('/api/users/logout')
      .then(res=>
        { 
          contextValue.setAuth(false); 
          contextValue.setSuperUser(0); 
          handleCloseNavMenu(); 
          handleCloseUserMenu();     
          navigate("/");  
        })
        .catch(err=> console.log(err));
    }
   

    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
    

    return (
    <>
    {editTrenerActive&&<FormDialog title={"Профиль пользователя "+contextValue.name} FormDialog width={'lg'}  active={editTrenerActive} setActive={seteditTrenerActive} children={TrenerPage}/>}
    <AppBar position="static" style={{ background: '#171718' }} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <Container maxWidth="xl">
      <Toolbar disableGutters >
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        
      {
      props.isAuth && 
                  <>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >            
                  <MenuItem 
                  key="treners" 
                  component={NavLink}
                  to="/treners"
                  >
                  <Typography textAlign="center">Сотрудники</Typography>
                  </MenuItem>  
                   <MenuItem 
                  key="sportsmens" 
                  component={NavLink}
                  to="/sportsmens"
                  >
                  <Typography textAlign="center">Спортсмены</Typography>
                  </MenuItem>  
                  <MenuItem 
                  key="orders" 
                  component={NavLink}
                  to="/orders"
                  >
                  <Typography textAlign="center">Заявки</Typography>
                  </MenuItem> 
                   <MenuItem 
                  key="championats" 
                  component={NavLink}
                  to="/championats"
                  >
                  <Typography textAlign="center">Соревнования</Typography>
                  </MenuItem>     
                  <MenuItem 
                  key="sostavs" 
                  component={NavLink}
                  to="/sostavs"
                  >
                  <Typography textAlign="center">Составы</Typography>
                  </MenuItem>     
                  <MenuItem 
                  key="logoit" 
                  onClick={handleDelete}
                  >
                  <Typography textAlign="center">Выход</Typography>
                  </MenuItem>     
                                                  
              
            </Menu>
            
            </>
            }
          </Box>

      <Box sx={{ flexGrow: 1,alignItems: 'center', display: { xs: 'flex', md: 'flex' } }}>        
      <img src={logo} className="desktop-base-header"></img> 
      <Typography
            variant="h3"
            noWrap
            align="center"
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Poppins',
              textAlign: 'center',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <strong>ACRO</strong>pro
        </Typography>  
        </Box>
          <Box sx={{ flexGrow: 0,alignItems: 'center', display: { xs: 'none', md: 'flex' } }}>
              {!props.isAuth && 
              <Button
                key="main"
                component={NavLink}
                to="/"
                sx={{ my: 2, color: 'white', display: 'block','&.active': {
                  backgroundColor:'white',
                  color: "black",
                }}}
              >
                Главная
              </Button>}
              {props.isAuth &&
              <>              
              <Button
                key="championats"
                component={NavLink}
                to="/championats"
                sx={{ my: 2, color: 'white', display: 'block' , 
                '&.active': {
                  backgroundColor:'white',
                  color: "black",
                }}}
              >Соревнования</Button>
              <Button
                key="orders"
                component={NavLink}
                to="/orders"
                sx={{ my: 2, color: 'white', display: 'block' , 
                '&.active': {
                  backgroundColor:'white',
                  color: "black",
                }}}
              >Заявки</Button>
              <Button
                key="treners"
                component={NavLink}
                to="/treners"
                sx={{ my: 2, color: 'white', display: 'block' , 
                '&.active': {
                  backgroundColor:'white',
                  color: "black",
                }}}
              >Сотрудники</Button>
              <Button
                key="sportsmens"
                component={NavLink}
                to="/sportsmens"
                sx={{ my: 2, color: 'white', display: 'block' , 
                '&.active': {
                  backgroundColor:'white',
                  color: "black",
                }}}
              >
                Спортсмены
              </Button>
              <Button
                key="sostavs"
                component={NavLink}
                to="/sostavs"
                sx={{ my: 2, color: 'white', display: 'block','&.active': {
                  backgroundColor:'white',
                  color: "black",
                }}}
              >
                Составы
              </Button> </> }
             
          </Box>
          {props.isAuth &&
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>
            
            <Tooltip title="Профиль">              
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                <Typography sx={{ p: 1 }} style={{color:'white'}} textAlign="right">{contextValue.name}</Typography>
                <Avatar />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >              
                <MenuItem key="Profile" onClick={()=>
                {
                  contextValue.userType===0?contextValue.setSelectTrener(null):contextValue.setSelectTrener(contextValue.trener_id)
                  seteditTrenerActive(true)
                }
                }>
                  <Typography textAlign="center">Профиль</Typography>
                </MenuItem>
                <MenuItem key="LogOut" onClick={handleDelete}>
                  <Typography textAlign="center">Выход</Typography>
                </MenuItem>              
            </Menu>
          </Box>}

      </Toolbar>
    </Container>

</AppBar>
    </>
       );
}
 
export default Navbar;