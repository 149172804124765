import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import Button from '@mui/material/Button';
import React from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs,{ tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TrenersTable from "../components/trenersTable/TrenersTable";
import FormDialog from "./../ModalWindow/Dialog/FormDialog";
import AddTrener from "./../ModalWindow/AddTrener/AddTrener";
import { Helmet } from 'react-helmet';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';



function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && ( 
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  

const Treners = (props) => {
    const[addTrenerActive,setaddTrenerActive]=useState(false)
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [update, setUpdate] = React.useState(true);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };
  
    useEffect(()=>{
      !addTrenerActive && setUpdate(true)
    },[addTrenerActive])


    return (       
        <>     
         <Helmet>
          <title>Спортсмены</title>
          <meta name="description" content="Управляйте своими спортсменами" />
        </Helmet>     
        <FormDialog title={"Новый сотрудник"} active={addTrenerActive} setActive={setaddTrenerActive} children={AddTrener}/>
            <div class="project-details"> 
                <div className="max-w-full">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">                   
                            
                        <Box
                            sx={{
                                flexgrow: 1,
                            bgcolor: 'background.paper',
                            flexWrap: 'wrap' 
                            }}>
                            <Button 
                                  variant="contained"
                                  startIcon={<AddOutlinedIcon />} 
                                  size="large"
                                  key="addChampionat"
                                  onClick={()=>setaddTrenerActive(true)}
                                  fullWidth
                                  style={{display:'flex',maxHeight: '100px', minHeight: '50px',borderRadius:0,marginBottom:'10px'}}
                                >Новый сотрудник</Button>
                              <Tabs
                                value={value}
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="scrollable"
                               
                              >
                                <Tab label="Все сотрудники" {...a11yProps(0)} />
                                <Tab label="Тренеры" {...a11yProps(1)} />
                                <Tab label="Судьи" {...a11yProps(2)} />
                                <Tab label="Секретари" {...a11yProps(3)} />
                                <Tab label="Сотрудники в архиве" {...a11yProps(4)} />
                              </Tabs>
                            <SwipeableViews
                              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                              index={value}
                              onChangeIndex={handleChangeIndex}
                            >
                              <TabPanel value={value} index={0} dir={theme.direction}>
                              <TrenersTable type={"all"} update={update} setUpdate={setUpdate}/>
                              </TabPanel>
                              <TabPanel value={value} index={1} dir={theme.direction}>
                              <TrenersTable type={"trener"} update={update} setUpdate={setUpdate}/>
                              </TabPanel>
                              <TabPanel value={value} index={2} dir={theme.direction}>
                              <TrenersTable type={"sud"} update={update} setUpdate={setUpdate}/>
                              </TabPanel>
                              <TabPanel value={value} index={3} dir={theme.direction}>
                              <TrenersTable type={"secret"} update={update} setUpdate={setUpdate}/>
                              </TabPanel>
                              <TabPanel value={value} index={4} dir={theme.direction}>
                              <TrenersTable type={"archive"} update={update} setUpdate={setUpdate}/>
                              </TabPanel>
                            </SwipeableViews>
                          </Box>
                          
                        </div>
                    </div>
                </div>
            
            </>
     );
}
 
export default Treners;