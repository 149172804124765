import vk from "./../../img/icons/vk.svg";
import instagram from "./../../img/icons/instagram.svg";
import twitter from "./../../img/icons/twitter.svg";
import gitHub from "./../../img/icons/gitHub.svg";
import linkedIn from "./../../img/icons/linkedIn.svg";

import "./style.css";

const Footer = () => {
    return ( 
        <footer className="footer">
        <div class="container">
            <div class="footer__wrapper">
                <ul class="social">
                    <li class="social__item"><a href="https://vk.com/acrokras"><img src={vk}alt="Link"/></a></li>
                    <li class="social__item"><a href="https://www.instagram.com/acrosport_krsk/"><img src={instagram} alt="Link"/></a></li>
                </ul>
                <div class="copyright">
                    <p>Нас можно найти в социальных сетях</p>
                </div>
            </div>
        </div>
      </footer>
     );
}
 
export default Footer;