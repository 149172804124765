import { useEffect, useState ,useContext} from "react";
import Button from '@mui/material/Button';
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChampionatMembers from "./ChampionatMembers";
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';



const ChampionatStatistics = (props) => {
    
    
  const [ages,setAges]=useState([]);
  const [razryads,setRazryads]=useState([])
  const [types,setTypes]=useState([])
  const [filtredOrders,setFiltredOrders]=useState([])
  const [sportsmensCount,setSportsmensCount]=useState('');
  
  const[showOrders,setShowOrders]=useState(false)


    useEffect(()=>
    {      
      setAges(Array.from(new Set(props.orders?.sort((a, b)=>a.group_category_id-b.group_category_id).map((obj)=>obj.group_category_name))))
      setRazryads(Array.from(new Set(props.orders?.sort((a, b)=>a.razryad_id-b.razryad_id).map(obj=>obj.razryad_short_name))))
      setTypes(Array.from(new Set(props.orders?.sort((a, b)=>a.sostav_type_id-b.sostav_type_id).map(obj=>obj.sostav_type_name))))
      setSportsmensCount(getSportsmenCount(props.orders))
    },[props.orders])


 
      function getCount(a,r,t)
      {
        let count=props.orders?.filter((o)=>o.group_category_name===a && o.razryad_short_name===r && o.sostav_type_name===t).length;
        return count>0?count:'';
      }

      function showFiltredOrders(a,r,t)
      {
        setFiltredOrders(props.orders?.filter((o)=>o.group_category_name===a && o.razryad_short_name===r && o.sostav_type_name===t));
        setShowOrders(true);

      }

      function getCountAll(t)
      {
        
        let count=props.orders?.filter((o)=>o.sostav_type_name===t).length;
        return count>0?count:'';
      }
      function getSportsmenCount(mass)
      {
        let sum=0;
        mass?.map(x => sum += x?.FIO?.split('\n').length)
        return sum;
      }


    return (       
        
                      <>
                      <FormDialog width={'lg'} title="Участники" active={showOrders} setActive={setShowOrders} children={ChampionatMembers} orders={filtredOrders}/>
                      <Stack direction='row' justifyContent='center'>
                              <Typography
                                    variant="h6"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                      <Typography>Статистика соревнования (<strong>Составов</strong>: {props.orders.length}, <strong>Спортсменов</strong>:{sportsmensCount})</Typography>
                                    </Typography>
                        </Stack>
                        <TableContainer component={Paper}>
                              <Table size="small" aria-label="simple table">
                                <TableHead>                               
                                  <TableRow>
                                    <TableCell align="center">Разряд/Вид</TableCell>           
                                    {types.length>0 && types?.map((t)=>(                                    
                                      <TableCell align="center">{t}</TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {ages.length>0 && ages?.map((a)=>(
                                  <>
                                  <TableRow sx={{maxHeight:15}}>
                                    <TableCell align="center" colSpan={types.length+1}><strong>{a}</strong></TableCell>                                    
                                  </TableRow> 
                                  { Array.from(
                                    new Set(props.orders?.sort((a, b)=>a.razryad_id-b.razryad_id).filter((o)=>o.group_category_name===a)?.map(obj=>obj.razryad_short_name)))?.map((r)=>(
                                    <TableRow sx={{maxHeight:15}}>
                                    <TableCell align="center">{r}</TableCell> 
                                    {types?.map((t)=>(                                    
                                      <TableCell sx={{maxHeight:15}} align="center">{getCount(a,r,t) && <Button variant="contained" onClick={()=>showFiltredOrders(a,r,t)}>{getCount(a,r,t)}</Button>}</TableCell>
                                    ))}                                     
                                    </TableRow>                                                                       
                                    ))}
                                      
                                    </>                                
                                  ))}
                                  <TableRow>
                                      <TableCell align="center"><strong>Итого</strong></TableCell> 
                                      {types?.map((t)=>(                                    
                                        <TableCell align="center">
                                          <strong>{getCountAll(t)}</strong>                                         
                                            </TableCell>
                                      ))}  
                                    </TableRow>
                                </TableBody>
                                </Table>
                                </TableContainer>    
                      </>                      
     );
}
 
export default ChampionatStatistics;