import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import AddSportsmen from "../ModalWindow/AddSportsmen/AddSportsmen";
import Button from '@mui/material/Button';
import React from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs,{ tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import { useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddSostavInOrder from "../components/addMemberInOrder/AddSostavInOrder";
import EditMember from "../components/addMemberInOrder/AddSostavInOrder";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import Grid from '@mui/material/Grid';
import { NavLink } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Tooltip from '@mui/material/Tooltip';
import logo from "./../img/Logo.png"
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Fab from '@mui/material/Fab';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { styled } from '@mui/material/styles';
  
const PrintButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
    top: theme.spacing(10),
    right: theme.spacing(2)
}));

const Order = (props) => {
    
    const contextValue=useContext(Context)
    const [order_id, setOrderID] =useState(null)
    const [order, setOrder] =useState([])
    const[editOrderMember,setEditOrderMember]=useState(false)    
    const [archive, setArchive] =useState([])
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    useEffect(()=>
      {
        if(contextValue.championat_id!==null)
        {
          
        axios.post(`/api/orders/get_order_id/${contextValue.championat_id}/${contextValue.school_id}`)
        .then(res=>{
          console.log(res.data.Status)
          if(res.data.Status==="Получен идентификатор заявки")
          {                            
             console.log("Получен идентификатор заявки: "+res.data.order_id)
             setOrderID(res.data.order_id)
             setArchive(res.data.order_archive) 
          }
          else alert(res.data.Error);})
        .catch(err=>console.log(err)) 
      }
    },[contextValue.championat_id]);

      useEffect(()=>
      {
        if(order_id!==null)
          contextValue.championat.championat_style_name!=="Парно-групповое"?
             axios.post(`/api/orders/get_order_sportsmen/${order_id}`)
              .then(res=>{
                  setOrder(res.data)                           
                  console.log("Заявка получена")  
                  console.log(res.data)
                })
              .catch(err=>console.log(err))  
            :
            axios.post(`/api/orders/get_order_sostavs_print/${order_id}`)
            .then(res=>{
                setOrder(res.data)                           
                console.log("Заявка получена")  
                console.log(res.data)
              })
            .catch(err=>console.log(err))  
        
      },[order_id,contextValue.setOpenAlert]);

    return (       
        <>
            <Helmet>
        <title>Печать заявки</title>
        <meta name="description" content="Печать заявки" />
      </Helmet>      
      <PrintButton color="secondary" aria-label="add" onClick={handlePrint}>
                  <PrintOutlinedIcon />
                </PrintButton>      
            <div class="project-details"> 
                <div className="max-w-full">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                     <Box
                            
                            sx={{
                                flexgrow: 1,
                            bgcolor: 'background.paper',
                            flexWrap: 'wrap', 
                            alignItems:'center',
                            justifyContent:'center'
                            }}>
                        <Typography
                         variant="h6"                         
                         sx={{
                          my:1,
                           fontFamily: 'Poppins',
                           color: 'inherit',
                           whiteSpace: "pre-wrap"
                         }}>
                        {`ЗАЯВКА\nна участие в соревнованиях (${contextValue.championat.championat_type_name} ${contextValue.championat.championat_style_name})\n"${contextValue.championat.championat_name}"\nпроводимых ${contextValue.championat.championat_start_date} - ${contextValue.championat.championat_end_date}, \nот ${contextValue.school.school_name},\n Место проведения: ${JSON.parse(contextValue.championat.championat_adress)?.data?.country!==null?JSON.parse(contextValue.championat.championat_adress)?.data.country:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.region_with_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data.region_with_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.city!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data.city}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.street_with_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data?.street_with_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.house_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data?.house_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.house!==null?`,${JSON.parse(contextValue.championat.championat_adress)?.data?.house}`:""} , представитель команды ${contextValue.name}\n`}
                        </Typography>
                        
                        
                        <Box
                            sx={{
                                flexgrow: 1,
                            bgcolor: 'background.paper',
                            flexWrap: 'wrap' 
                            }}>
                              
                          </Box>
                          <div  ref={componentRef}>
                          <TableContainer sx={{ my:1}} >
        <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                  '& .MuiTableCell-sizeSmall': {
                    padding: '1px',
                  },
                }}
              > 
                      <TableBody> 
                      <TableRow>
                        <TableCell align='center' style={{width: 100, border:0}} rowSpan={4}>
                          <img width={100} src={logo}></img> 
                        </TableCell>
                        <TableCell style={{width: '60%', border:0}} align='center' rowSpan={4}>
                            
                        </TableCell>
                        <TableCell style={{width: '30%', border:0}} align='center'>
                            <Typography fontSize={24}>«УТВЕРЖАЮ»</Typography>
                        </TableCell>
                      </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0}} align='center'>
                            <Typography fontSize={24}>Председатель Красноярской краевой федерации спортивной акробатики</Typography>
                          </TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0}} align='center'>
                            <Typography fontSize={24}> _______________И.М.Бородина</Typography>
                          </TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0}} align='center'>
                            <Typography fontSize={24}>« ___ » _____________ 2023 г. </Typography>
                          </TableCell>
                          </TableRow>
                      </TableBody>
                      </Table>
                      </TableContainer> 
                          <TableContainer sx={{ my:1}}>
                          <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                                      '& .MuiTableCell-sizeSmall': {
                                      padding: '1px',
                                      },
                                }}
                              > 
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ border: 1 }}>№ п/п</TableCell>            
                                    <TableCell align="center" sx={{ border: 1 }}>Фамилия, имя, отчество</TableCell>
                                    <TableCell align="center" sx={{ border: 1 }}>Функция</TableCell>                                   
                                    {contextValue.championat.championat_style_name==="Парно-групповое" && <TableCell align="center" sx={{ border: 1 }}>Дисциплина акробатики</TableCell>}
                                    <TableCell align="center" sx={{ border: 1 }}>ГОД РОЖДЕНИЯ</TableCell>
                                    <TableCell align="center" sx={{ border: 1 }}>Ведомство</TableCell>
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center" sx={{ border: 1 }}>РАЗРЯД ИМЕЕТ</TableCell>}
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center" sx={{ border: 1 }}>ВЫСТУПАЕТ ПО РАЗРЯДУ</TableCell>}
                                    <TableCell align="center" sx={{ border: 1 }}>Ф.И.О. тренера</TableCell>
                                    <TableCell align="center" sx={{ border: 1 }}>Виза врача, печать</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {order.length>0 && order?.map((o,i)=>(
                                      <TableRow>
                                    <TableCell component="th" scope="row" sx={{ border: 1 }}>
                                            {i+1}
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1
                                            }} align="center">
                                            {o.FIO}            
                                    </TableCell>  
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1
                                            }} align="center">
                                            Спортсмен           
                                    </TableCell>  
                                    {contextValue.championat.championat_style_name==="Парно-групповое" && 
                                    <TableCell align="center" sx={{ border: 1 }}>
                                            {o.sostav_type_name_short}                    
                                    </TableCell>}              
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1
                                            }} align="center">           
                                            {o.sportsmen_age}                    
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1
                                            }} align="center">           
                                            {o.school_name}                    
                                    </TableCell>
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1
                                            }} align="center">            
                                            {o.razryad_name}                      
                                    </TableCell>  } 
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center" sx={{ border: 1 }}>             
                                            {o.razryad_short_name}                          
                                    </TableCell> }
                                    {contextValue.championat.championat_style_name==="КПН" && <TableCell align="center" sx={{ border: 1 }}>             
                                            {o.kpn}                          
                                    </TableCell> } 
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1
                                            }} align="center"> 
                                              {o.treners}  
                                    </TableCell>          
                                    <TableCell sx={{minWidth:"80px", border: 1 }} align="center">
                                    
                                      </TableCell>
                                      </TableRow>))}          
                                    </TableBody>
                              </Table>
                            </TableContainer> 


                            </div>
                            </Box>
                            
                        </div>
                    </div>
                </div>
          </>  
     )
}
 
export default Order;