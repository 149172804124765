import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import AddSportsmen from "../ModalWindow/AddSportsmen/AddSportsmen";
import AllSportsmensTable from "../components/sportsmensTable/AllSportsmensTable"
import Button from '@mui/material/Button';
import React from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs,{ tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FormDialog from "./../ModalWindow/Dialog/FormDialog";



function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  

const Sportsmens = (props) => {
    
    const contextValue=useContext(Context)
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const[addSportsmenActive,setaddSportsmenActive]=useState(false)
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };
  


    return (       
        <>
        <Helmet>
        <title>Спортсмены</title>
        <meta name="description" content="Управляйте своими спортсменами" />
      </Helmet>        
        <FormDialog title="Новый спортсмен" active={addSportsmenActive} setActive={setaddSportsmenActive} children={AddSportsmen}/>
            <div class="project-details"> 
                <div className="max-w-full">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                        <Box
                            sx={{
                                flexgrow: 1,
                            bgcolor: 'background.paper',
                            flexWrap: 'wrap' 
                            }}>
                              <Button 
                                  variant="contained"
                                  startIcon={<AddOutlinedIcon />} 
                                  size="large"
                                  key="addChampionat"
                                  onClick={()=>setaddSportsmenActive(true)}
                                  fullWidth
                                  style={{display:'flex',maxHeight: '100px', minHeight: '50px',borderRadius:0,marginBottom:'10px'}}
                                >Новый спортсмен</Button>
                              <Tabs
                                value={value}
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="scrollable"
                               
                              >
                                
                               
                                
                                <Tab label="Все спортсмены" {...a11yProps(1)} />
                                {contextValue.trener_id!==null?
                                <Tab label="Мои спортсмены" {...a11yProps(0)} />
                                :
                                <Tab label="Cпортсмены без тренера" {...a11yProps(0)} />}
                                <Tab label="Спортсмены в архиве" {...a11yProps(2)} />
                              </Tabs>
                            <SwipeableViews
                              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                              index={value}
                              onChangeIndex={handleChangeIndex}
                            >
                              <TabPanel value={value} index={0} dir={theme.direction}>
                              <AllSportsmensTable type={"All"}/>
                              </TabPanel>
                              <TabPanel value={value} index={1} dir={theme.direction}>
                              {/* <SportsmensTable/> */}
                              <AllSportsmensTable type={"My"}/>
                              </TabPanel>
                              <TabPanel value={value} index={2} dir={theme.direction}>                                
                                {/* <SportsmensTableArchive/> */}
                              <AllSportsmensTable type={"Archive"}/>
                              </TabPanel>
                            </SwipeableViews>
                          </Box>
                          
                        </div>
                    </div>
                </div>
           
            </>
     );
}
 
export default Sportsmens;